/************************************************
Breakpoints
************************************************/
/************************************************
Printer Formatting
************************************************/
/************************************************
Colors
************************************************/
/************************************************
Imports
************************************************/
@font-face {
  font-family: "ProductSans";
  src: url("/src/assets/fonts/Product_Sans_400.ttf");
}
/************************************************
Browser Resets
************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
rem,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
rembed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/************************************************
Older Browser Resets
************************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/************************************************
ADA Page Tabbing
************************************************/
button:focus,
input:focus,
a:focus,
textarea:focus,
select:focus {
  outline: none !important;
  outline-style: none !important;
  border-color: inherit !important;
  box-shadow: none !important;
}

/************************************************
ADA User Tabbing
************************************************/
body.user-is-tabbing *:focus {
  outline: 2px solid #7AACFE !important;
  /* for non-webkit browsers */
  outline: 5px auto -webkit-focus-ring-color !important;
}

/************************************************
HTML
************************************************/
* {
  box-sizing: border-box;
}

html {
  max-width: 100%;
  scroll-behavior: smooth;
  font-size: 25px;
  /************************************************
  Body
  ************************************************/
}
html.no-scroll {
  overflow: hidden;
}
@media (max-width: 1600px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 18px;
  }
}
html body {
  overflow-x: hidden;
  max-width: 100%;
  height: auto;
  /************************************************
  Font Sizing
  ************************************************/
}
html body h1,
html body h2,
html body h3,
html body h4,
html body h5,
html body h6 {
  font-weight: 600;
  font-family: "ProductSans", sans-serif;
  margin-bottom: 15px;
}
html body p,
html body a,
html body span,
html body ul,
html body ol,
html body li {
  font-weight: 300;
  font-family: "ProductSans", sans-serif;
}
html body a {
  text-decoration: none;
}
html body a:visited {
  color: #1FB5EB;
}
html body h1 a, html body h2 a, html body h3 a, html body h4 a, html body h5 a, html body h6 a {
  font-size: inherit;
}
html body h1 {
  font-size: 2.5rem;
}
@media (max-width: 1600px) {
  html body h1 {
    font-size: 1.8rem;
  }
}
html body h2 {
  font-size: 2.25rem;
}
@media (max-width: 768px) {
  html body h2 {
    font-size: 2rem;
  }
}
html body h3 {
  font-size: 2rem;
}
@media (max-width: 768px) {
  html body h3 {
    font-size: 1.8rem;
  }
}
html body h4 {
  font-size: 1.75rem;
}
@media (max-width: 768px) {
  html body h4 {
    font-size: 1.5rem;
  }
}
html body h5 {
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  html body h5 {
    font-size: 1.2rem;
  }
}
html body h6 {
  font-size: 1.25rem;
}
html body p,
html body ul,
html body li,
html body ol,
html body h1,
html body h2,
html body h3,
html body h4,
html body h5,
html body h6 {
  color: #333333;
}
html body p,
html body a,
html body ul,
html body ol,
html body li {
  font-size: 1rem;
}
html body p {
  line-height: 1.25;
  margin-bottom: 15px;
}
html body p strong {
  font-weight: bold;
}
html body #root .App {
  display: flex;
  justify-content: flex-end;
  /************************************************
   * Global
  ************************************************/
}
html body #root .App.print-schedule {
  display: block;
}
html body #root .App .top-preference {
  font-size: 14px;
  color: #FF5F5F;
}
html body #root .App label {
  font-family: "ProductSans", sans-serif;
  font-size: 0.9rem;
}
html body #root .App .minus {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #FF5F5F;
  color: white;
  width: 20px;
  height: 20px;
  font-size: 15px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
html body #root .App .campers-wrapper {
  display: flex;
  flex-flow: column;
  gap: 15px;
  width: 100%;
  margin-top: 30px;
}
html body #root .App .campers-wrapper .camper {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  width: 100%;
}
html body #root .App .campers-wrapper .camper h2 {
  text-align: left;
  width: 100%;
  border-bottom: 2px solid;
  padding-bottom: 7px;
  font-size: 1.1rem;
}
html body #root .App .campers-wrapper .camper h6 {
  font-size: 16px;
  margin-bottom: 0;
  padding: 5px;
}
html body #root .App .campers-wrapper .camper .activity-block {
  display: flex;
  width: 100%;
  position: relative;
}
html body #root .App .campers-wrapper .camper .activity-wrapper {
  text-align: left;
  width: 100%;
  position: relative;
  flex-flow: column;
}
html body #root .App .campers-wrapper .camper .activity-wrapper:first-of-type {
  text-align: right;
  padding-right: 17px;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  left: -35px;
  top: 0;
  height: 100%;
}
html body #root .App .campers-wrapper .camper .activity-wrapper:first-of-type:after {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  width: 2px;
  height: 100%;
  background: #9B9B9B;
}
html body #root .App .campers-wrapper .camper .activity-wrapper.days h6:nth-child(even) {
  background: #e7e7e7;
}
html body #root .App .campers-wrapper .camper .day {
  width: 100%;
}
html body #root .App .campers-wrapper .camper .day h6:nth-child(even) {
  background: #e7e7e7;
}
html body #root .App .printer {
  height: 35px;
  width: 35px;
  margin-bottom: 15px;
  cursor: pointer;
}
html body #root .App .counselors-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 30px;
  gap: 30px;
}
html body #root .App .counselors-wrapper .counselor-wrapper .name {
  color: #1FB5EB;
}
html body #root .App .counselors-wrapper .counselor-wrapper .activity-name {
  color: #333333;
  opacity: 0.8;
}
html body #root .App .counselors-wrapper .counselor-wrapper .role {
  color: #FF5F5F;
  font-weight: bold;
  opacity: 0.8;
}
html body #root .App .counselors-wrapper .counselor-wrapper .period-off, html body #root .App .counselors-wrapper .counselor-wrapper .unavailable {
  font-weight: 700;
}
html body #root .App .counselors-wrapper .counselor-wrapper .period-off {
  color: #1FB5EB;
}
html body #root .App .counselors-wrapper .counselor-wrapper .unavailable {
  color: #FF5F5F;
}
html body #root .App .counselors-wrapper .counselor-wrapper .day {
  text-transform: capitalize;
}
html body #root .App .counselors-wrapper .counselor {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  width: 100%;
}
html body #root .App .counselors-wrapper .counselor h2 {
  text-align: left;
  width: 100%;
  border-bottom: 2px solid;
  padding-bottom: 7px;
  font-size: 1.1rem;
}
html body #root .App .counselors-wrapper .counselor h6 {
  font-size: 16px;
  margin-bottom: 0;
  padding: 5px;
}
html body #root .App .counselors-wrapper .counselor .day {
  width: 100%;
}
html body #root .App .counselors-wrapper .counselor .day h6:nth-child(even) {
  background: #e7e7e7;
}
html body #root .App .counselors-wrapper .counselor {
  gap: 10px;
  row-gap: 20px;
}
html body #root .App .required-cabins-wrapper {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  justify-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
}
html body #root .App .required-cabins-wrapper .cabin-wrapper {
  padding: 5px 10px;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  color: #9B9B9B;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
html body #root .App .required-cabins-wrapper .cabin-wrapper.active {
  background: #1FB5EB;
  border-color: #1FB5EB;
  color: #FFFFFF;
}
html body #root .App .counselors-by-day-wrapper {
  display: flex;
  flex-flow: column;
}
html body #root .App .counselors-by-day-wrapper .day {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1FB5EB;
  font-family: "ProductSans", sans-serif;
  margin-bottom: 15px;
  text-transform: capitalize;
  margin-top: 30px;
}
html body #root .App .counselors-by-day-wrapper .name {
  font-weight: 800 !important;
}
html body #root .App .counselors-by-day-wrapper .counselor-row {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  text-align: center;
  padding: 10px 5px;
}
html body #root .App .counselors-by-day-wrapper .counselor-row:nth-child(even) {
  background-color: #efefef;
}
html body #root .App .counselors-by-day-wrapper .counselor-row h6 {
  font-size: 16px;
  font-weight: 300;
  color: #333333;
  font-family: "ProductSans", sans-serif;
  margin-bottom: 0;
}
html body #root .App .counselors-by-day-wrapper .counselor-row .unavailable {
  color: #1FB5EB;
}
html body #root .App .counselors-by-day-wrapper .counselor-row .period-off {
  color: #1FB5EB;
}
html body #root .App .counselors-by-day-wrapper .counselor-row .missing {
  color: #FF5F5F;
}
html body #root .App .available-periods-wrapper, html body #root .App .available-days-wrapper {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  justify-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
}
html body #root .App .available-periods-wrapper .period-wrapper, html body #root .App .available-days-wrapper .period-wrapper {
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  color: #9B9B9B;
  cursor: pointer;
}
html body #root .App .available-periods-wrapper .period-wrapper.active, html body #root .App .available-days-wrapper .period-wrapper.active {
  background: #1FB5EB;
  border-color: #1FB5EB;
  color: #FFFFFF;
}
html body #root .App .available-days-wrapper {
  grid-template-columns: repeat(4, 1fr);
}
html body #root .App .available-days-wrapper .period-wrapper {
  width: 100%;
  text-align: center;
}
html body #root .App .attribute-values {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(5, 1fr);
}
html body #root .App .attribute-values h6 {
  margin-bottom: 0;
}
html body #root .App .attribute-values .attribute-value-btn {
  padding: 2px 10px;
  border: solid 1px #9B9B9B;
  border-radius: 10px;
  font-size: 0.8rem;
  cursor: pointer;
  position: relative;
}
html body #root .App .attribute-values .attribute-value-btn.active {
  background: #1FB5EB;
  border-color: #1FB5EB;
  color: #FFFFFF;
}
html body #root .App .add-cabin {
  font-family: "ProductSans", sans-serif;
  margin-top: 10px;
  cursor: pointer;
}
html body #root .App .btn-wrapper {
  display: flex;
  gap: 10px;
}
html body #root .App .add-btn {
  font-family: "ProductSans", sans-serif;
  padding: 10px;
  background: #1FB5EB;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
html body #root .App .delete-btn {
  font-family: "ProductSans", sans-serif;
  padding: 10px;
  background: #FF5F5F;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
html body #root .App button.delete {
  position: relative;
  margin-top: 50px;
  margin-left: 20px;
  background: #FF5F5F;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 0.9rem;
  cursor: pointer;
}
html body #root .App #scheduler, html body #root .App #reports {
  width: 100%;
  padding: 50px;
  max-width: 80%;
  display: flex;
  flex-flow: column;
}
html body #root .App #scheduler h1, html body #root .App #reports h1 {
  font-size: 1.3rem;
}
html body #root .App #scheduler h2, html body #root .App #reports h2 {
  font-size: 1.1rem;
}
html body #root .App #reports .btn {
  position: relative;
  background: transparent;
  border: 0;
  cursor: pointer;
}
html body #root .App #reports .btn h6 {
  color: #FFFFFF;
  background: #FF5F5F;
  font-size: 0.9rem;
  padding: 13px 55px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
}
html body #root .App #reports .cabin-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
html body #root .App #reports .schedule-types {
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  gap: 5px;
}
html body #root .App .cabin-groups {
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-bottom: 20px;
}
html body #root .App .cabin-groups select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  font-size: 0.9rem;
  font-family: "ProductSans", sans-serif;
  width: 100%;
}
html body #root .App .activity-preferences {
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-bottom: 20px;
}
html body #root .App .activity-preferences .btn-wrapper {
  margin: 10px 0;
}
html body #root .App .activity-preferences button {
  padding: 7px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-family: "ProductSans", sans-serif;
  cursor: pointer;
  background: #1FB5EB;
  color: white;
}
html body #root .App .location-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
html body #root .App .cabin-wrapper {
  display: flex;
  gap: 10px;
}
html body #root .App .cabin-wrapper button {
  padding: 5px 7px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  font-size: 0.9rem;
  font-family: "ProductSans", sans-serif;
  background: transparent;
  pointer-events: none;
}
html body #root .App .sidebar {
  padding-top: 45px;
  width: 380px;
  max-width: 20%;
  height: 100vh;
  background: #F5F8FA;
  position: fixed;
  top: 0;
  left: 0;
}
@media (max-width: 1600px) {
  html body #root .App .sidebar {
    padding-top: 20px;
  }
}
html body #root .App .sidebar .sidebar-top {
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
  flex-flow: column;
  gap: 10px;
}
html body #root .App .sidebar .sidebar-top .logo {
  width: 75px;
  height: 75px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 50%;
}
@media (max-width: 1600px) {
  html body #root .App .sidebar .sidebar-top .logo {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 1200px) {
  html body #root .App .sidebar .sidebar-top .logo {
    width: 40px;
    height: 40px;
  }
}
html body #root .App .sidebar .sidebar-top .user-info {
  text-align: left;
}
@media (max-width: 1600px) {
  html body #root .App .sidebar .sidebar-top .user-info {
    text-align: center;
  }
}
html body #root .App .sidebar .sidebar-top .user-info .name {
  color: #1FB5EB;
}
@media (max-width: 1600px) {
  html body #root .App .sidebar .sidebar-top .user-info .name {
    margin-bottom: 0;
  }
}
html body #root .App .sidebar .sidebar-top .user-info .email {
  font-size: 0.72rem;
  margin-bottom: 0;
}
html body #root .App .sidebar .sidebar-top .schedule-control {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}
html body #root .App .sidebar .sidebar-top .schedule-control select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  font-size: 0.9rem;
  font-family: "ProductSans", sans-serif;
  width: 100%;
}
html body #root .App .sidebar .sidebar-top .schedule-control .dupe-btn {
  position: relative;
  color: #1FB5EB;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.5rem;
}
html body #root .App .sidebar .sidebar-top .schedule-control .dupe-btn[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
html body #root .App .sidebar .sidebar-links .link {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 25px 60px;
}
html body #root .App .sidebar .sidebar-links .link h6 {
  font-weight: 400;
  font-size: 1.04rem;
  margin-bottom: 0;
}
html body #root .App .sidebar .sidebar-links .link img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  opacity: 0.6;
}
html body #root .App .sidebar .sidebar-links .link img.campers {
  width: 25px;
}
html body #root .App .sidebar .sidebar-links .link img.counselors {
  width: 22px;
}
html body #root .App .sidebar .sidebar-links .link img.activities {
  width: 25px;
}
html body #root .App .sidebar .sidebar-links .link img.scheduler {
  width: 21.5px;
}
html body #root .App .sidebar .sidebar-links .link img.reports {
  width: 21px;
}
html body #root .App .sidebar .sidebar-links .link.active {
  border-left: 10px solid #1FB5EB;
  background: #FFFFFF;
  padding-left: 50px;
}
html body #root .App .sidebar .sidebar-links .link.active h6 {
  font-weight: 700;
}
html body #root .App .sidebar .sidebar-links .link.active img {
  opacity: 1;
}
html body #root .App .sidebar .sidebar-links .link.active img.counselors path {
  fill: #1FB5EB;
}
html body #root .App .sidebar .signout-btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  border: 0;
  cursor: pointer;
}
html body #root .App .sidebar .signout-btn h6 {
  color: #FFFFFF;
  background: #FF5F5F;
  font-size: 0.9rem;
  padding: 5px 20px;
  border-radius: 10px;
  font-weight: 400;
  box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
}
html body #root .App .sidebar .btn {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1600px) {
  html body #root .App .sidebar .btn {
    bottom: 20px;
  }
}
html body #root .App .sidebar .btn h6 {
  color: #FFFFFF;
  background: #FF5F5F;
  font-size: 0.9rem;
  padding: 13px 55px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
}
html body #root .App .main-content {
  display: flex;
}
html body #root .App .search-wrapper {
  width: 580px;
  max-width: 30vw;
  height: 100vh;
  background: #F9FAF5;
  padding: 50px 30px;
  position: relative;
}
html body #root .App .search-wrapper input:not(input[type=checkbox]) {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  height: 45px;
  border-radius: 13px;
  box-shadow: none;
  border: none;
  padding-inline-start: 30px;
  font-size: 1rem;
  font-family: "ProductSans", sans-serif;
}
html body #root .App .search-wrapper input:not(input[type=checkbox])::-moz-placeholder {
  color: #9B9B9B;
}
html body #root .App .search-wrapper input:not(input[type=checkbox])::placeholder {
  color: #9B9B9B;
}
html body #root .App .search-wrapper #bulk-upload {
  padding: 5px 10px;
  background: white;
  color: #707070;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
}
html body #root .App .search-wrapper #bulk-upload-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 8;
}
html body #root .App .search-wrapper #bulk-upload-window {
  width: 70vw;
  height: 70vh;
  background: white;
  box-shadow: 0px 20px 10px -17px rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  overflow: scroll;
}
html body #root .App .search-wrapper #bulk-upload-window .top-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
html body #root .App .search-wrapper #bulk-upload-window #file {
  width: 100%;
  height: 50vh;
  border: 1px dashed #1FB5EB;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 15px;
  flex-flow: column;
}
html body #root .App .search-wrapper #bulk-upload-window #file #file-name {
  color: #FF5F5F;
}
html body #root .App .search-wrapper #bulk-upload-window .upload {
  background: #1FB5EB;
  border: unset;
  border-radius: 7px;
  height: 45px;
  width: 300px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  color: white;
  cursor: pointer;
  pointer-events: all;
}
html body #root .App .search-wrapper #bulk-upload-window .upload.disabled {
  pointer-events: none;
  opacity: 0.5;
}
html body #root .App .search-wrapper .filter-wrapper {
  display: flex;
  justify-content: space-between;
  font-family: "ProductSans", sans-serif;
}
html body #root .App .search-wrapper .filter-wrapper .filter-menu {
  width: 580px;
  max-width: 30vw;
  z-index: 9;
  position: absolute;
  background: gray;
}
html body #root .App .search-wrapper .filter-wrapper .filter-icons {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
html body #root .App .search-wrapper .filter-wrapper .filter-icons #sort-by, html body #root .App .search-wrapper .filter-wrapper .filter-icons #filter-by {
  width: 28px;
  height: 28px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #root .App .search-results {
  display: flex;
  flex-flow: column;
  gap: 15px;
  margin-top: 20px;
  height: calc(100% - 67px);
  overflow-y: scroll;
}
html body #root .App .search-results .search-card {
  padding: 30px;
  background: white;
  border-radius: 35px;
  text-align: left;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 15px;
  cursor: pointer;
  position: relative;
}
html body #root .App .search-results .search-card .name-wrapper {
  display: flex;
  gap: 5px;
}
html body #root .App .search-results .search-card .name-wrapper h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
}
html body #root .App .search-results .search-card .periods-title {
  font-size: 1rem;
}
html body #root .App .search-results .search-card p {
  margin-bottom: 0;
}
html body #root .App .search-results .search-card .notes {
  font-size: 0.9rem;
  color: #9B9B9B;
}
html body #root .App .search-results .search-card .special-activities {
  font-size: 0.9rem;
  color: #1FB5EB;
}
html body #root .App .search-results .search-card .periods-list {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  align-items: center;
}
html body #root .App .search-results .search-card .periods-list h6 {
  font-size: 0.9rem;
  margin-bottom: 0;
}
html body #root .App .search-results .search-card .periods-list .period {
  font-size: 0.9rem;
}
html body #root .App .name-info-wrapper {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
html body #root .App .name-info-wrapper.vertical {
  flex-flow: column;
}
html body #root .App .name-info-wrapper .location {
  font-size: 0.8rem;
  margin-bottom: 0;
}
html body #root .App .name-info-wrapper .name-input {
  border: unset;
  padding-left: 0;
  margin-left: 0;
  width: 48%;
}
html body #root .App .name-info-wrapper .name-input, html body #root .App .name-info-wrapper .title {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "ProductSans", sans-serif;
}
html body #root .App .name-info-wrapper .name {
  margin-bottom: 5px;
}
html body #root .App .name-info-wrapper .title {
  font-weight: 400;
  margin-bottom: 0;
}
html body #root .App .name-info-wrapper p {
  margin-bottom: 0;
}
html body #root .App .name-info-wrapper .is-admin-checkbox {
  margin-top: 10px;
}
html body #root .App .name-info-wrapper .is-admin {
  font-size: 0.9rem;
}
html body #root .App .detailed-info {
  width: 50vw;
  height: 100vh;
  padding: 50px 30px;
  font-family: "ProductSans", sans-serif;
  overflow-y: scroll;
}
html body #root .App .detailed-info .edit {
  font-size: 0.8rem;
  color: #9B9B9B;
}
html body #root .App .detailed-info .nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
html body #root .App .detailed-info .nav .info-count {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
html body #root .App .detailed-info .nav .info-count-numbers {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
html body #root .App .detailed-info .nav .info-count-numbers .info-arrows {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
html body #root .App .detailed-info .nav .info-count-numbers .info-arrows .arrow-left, html body #root .App .detailed-info .nav .info-count-numbers .info-arrows .arrow-right {
  width: 28px;
  height: 28px;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}
html body #root .App .detailed-info .nav .info-count-numbers .info-arrows .arrow-left {
  transform: rotate(180deg);
}
html body #root .App .detailed-info .nav .info-count-numbers .info-arrows .arrow-left.first {
  opacity: 0.5;
  cursor: default;
}
html body #root .App .detailed-info .info-wrapper {
  display: flex;
  flex-flow: column;
  gap: 30px;
  padding: 0 75px;
}
@media (max-width: 1350px) {
  html body #root .App .detailed-info .info-wrapper {
    padding: 0 25px;
  }
}
html body #root .App .detailed-info .info-wrapper .detail-info-wrapper h5 {
  font-size: 1.2rem;
  font-weight: bold !important;
}
html body #root .App .detailed-info .info-wrapper .detail-info-wrapper h5, html body #root .App .detailed-info .info-wrapper .detail-info-wrapper h6 {
  font-weight: 400;
}
html body #root .App .detailed-info .info-wrapper .detail-info-wrapper .counselor {
  grid-template-columns: repeat(2, 1fr);
}
html body #root .App .detailed-info .info-wrapper .detail-info-wrapper .title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
html body #root .App .detailed-info .info-wrapper .detail-info-wrapper .title-wrapper img {
  margin-bottom: 15px;
}
html body #root .App .detailed-info .info-wrapper .skills-wrapper h6, html body #root .App .detailed-info .info-wrapper .skills-wrapper .column-title, html body #root .App .detailed-info .info-wrapper .skills-wrapper .column-value, html body #root .App .detailed-info .info-wrapper .preferences-wrapper h6, html body #root .App .detailed-info .info-wrapper .preferences-wrapper .column-title, html body #root .App .detailed-info .info-wrapper .preferences-wrapper .column-value, html body #root .App .detailed-info .info-wrapper .days-wrapper h6, html body #root .App .detailed-info .info-wrapper .days-wrapper .column-title, html body #root .App .detailed-info .info-wrapper .days-wrapper .column-value, html body #root .App .detailed-info .info-wrapper .certifications-wrapper h6, html body #root .App .detailed-info .info-wrapper .certifications-wrapper .column-title, html body #root .App .detailed-info .info-wrapper .certifications-wrapper .column-value, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper h6, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper .column-title, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper .column-value, html body #root .App .detailed-info .info-wrapper .attributes-wrapper h6, html body #root .App .detailed-info .info-wrapper .attributes-wrapper .column-title, html body #root .App .detailed-info .info-wrapper .attributes-wrapper .column-value, html body #root .App .detailed-info .info-wrapper .requirements-wrapper h6, html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column-title, html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column-value {
  font-size: 0.8rem;
  color: #9B9B9B;
  font-weight: 400;
}
html body #root .App .detailed-info .info-wrapper .name-info-wrapper {
  display: flex;
  justify-content: space-between;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper {
  display: flex;
  gap: 6%;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column {
  display: flex;
  flex-flow: column;
  gap: 7px;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column .column-title {
  font-weight: bold;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column label {
  font-family: "ProductSans", sans-serif;
  font-size: 0.9rem;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column input:checked {
  accent-color: #1FB5EB;
  color: white;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column .checkboxes-wrapper {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
  grid-gap: 10px;
  width: 100%;
  height: auto;
}
html body #root .App .detailed-info .info-wrapper .requirements-wrapper .column .checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper {
  margin-bottom: 30px;
  gap: 20px;
  display: flex;
  flex-flow: column;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper h6 {
  margin-bottom: 15px;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper {
  display: flex;
  flex-flow: column;
  gap: 20px;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  font-size: 0.9rem;
  font-family: "ProductSans", sans-serif;
  width: 100%;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper .groups-change {
  display: flex;
  gap: 10px;
  align-items: center;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper .groups-change .add-another-group, html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper .groups-change .remove-group {
  cursor: pointer;
  color: #1FB5EB;
  font-weight: bold;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.shareable {
  margin-top: 10px;
  flex-flow: row;
  gap: 10px;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.shareable label {
  font-size: 0.9rem;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.location, html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.min-max-kids {
  display: flex;
  flex-flow: row;
  align-items: center;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.location h6, html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.min-max-kids h6 {
  margin-bottom: 0;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.location input, html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.min-max-kids input {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: solid 1px #9B9B9B;
  box-shadow: none;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.min-max-kids input {
  text-align: center;
  padding: 0 0 0 0;
  max-width: 40px;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.batch-by {
  display: flex;
  flex-flow: row;
  align-items: center;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.batch-by h6 {
  margin-bottom: 0;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.batch-by .css-b62m3t-container {
  font-size: 16px;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.requirements {
  display: flex;
  gap: 15px;
  flex-flow: row;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.requirements h6 {
  margin-bottom: 0;
  display: flex;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.requirements .requirement-buttons-wrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  gap: 15px;
}
html body #root .App .detailed-info .info-wrapper .attributes-wrapper .attribute-wrapper.requirements .requirement-buttons-wrapper .attribute-value-btn {
  width: -moz-max-content;
  width: max-content;
}
html body #root .App .detailed-info .info-wrapper .certifications-wrapper p {
  font-size: 0.9rem;
  margin-bottom: 0;
  color: #9B9B9B;
}
html body #root .App .detailed-info .info-wrapper .skills-wrapper, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
html body #root .App .detailed-info .info-wrapper .skills-wrapper .skill-type, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper .skill-type {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #9B9B9B;
}
html body #root .App .detailed-info .info-wrapper .skills-wrapper .box, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper .box {
  height: 15px;
  width: 15px;
  border: solid 1px #9B9B9B;
  margin-right: 15px;
}
html body #root .App .detailed-info .info-wrapper .skills-wrapper .skill, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper .skill {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}
html body #root .App .detailed-info .info-wrapper .skills-wrapper .skill h6, html body #root .App .detailed-info .info-wrapper .counseler-skills-wrapper .skill h6 {
  font-size: 0.9rem;
  color: #9B9B9B;
}
html body #root .App .detailed-info .info-wrapper .preferences-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 6%;
}
html body #root .App .detailed-info .info-wrapper .preferences-wrapper .preference {
  flex: 47%;
  display: flex;
  justify-content: space-between;
  max-width: 275px;
}
html body #root .App .detailed-info .info-wrapper .preferences-wrapper .preference h6 {
  font-size: 0.9rem;
  color: #9B9B9B;
}
html body #root .App .detailed-info .info-wrapper .days-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 6%;
}
html body #root .App .detailed-info .info-wrapper .days-wrapper .day {
  width: 250px;
}
html body #root .App .detailed-info .info-wrapper .days-wrapper h6 {
  font-size: 0.9rem;
  color: #9B9B9B;
}
html body #root .App .detailed-info .info-wrapper .days-wrapper h6.day-name {
  text-decoration: underline;
  font-weight: 600;
}
html body #root .App .detailed-info .info-wrapper .activity-wrapper {
  display: flex;
  justify-content: space-between;
}
html body #root .App .detailed-info .info-wrapper textarea {
  width: 100%;
  height: 165px;
  border-radius: 13px;
  padding-top: 20px;
  padding-left: 20px;
  font-size: 0.8rem;
}
html body #root .App #register {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  padding: 50px 0;
}
html body #root .App #register .signup-wrapper {
  display: flex;
  flex-flow: column;
  gap: 20px;
}
html body #root .App #register .signup-wrapper .signup-btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
}
html body #root .App #register .signup-wrapper .signup-btn h6 {
  text-align: center;
  color: #FFFFFF;
  background: #FF5F5F;
  font-size: 0.9rem;
  padding: 13px 55px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
}
html body #root .App #login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
html body #root .App #login .two-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}
html body #root .App #login .two-column .left, html body #root .App #login .two-column .right {
  width: 48%;
  height: 100%;
}
html body #root .App #login .two-column .right img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
html body #root .App #login .two-column .left {
  display: flex;
  position: relative;
  flex-flow: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}
html body #root .App #login .two-column .left .text-wrapper {
  width: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  gap: 15px;
}
html body #root .App #login .two-column .left input {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #9B9B9B;
  padding-left: 10px;
}
html body #root .App #login .two-column .left .login-btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
}
html body #root .App #login .two-column .left .login-btn h6 {
  text-align: center;
  color: #FFFFFF;
  background: #FF5F5F;
  font-size: 0.9rem;
  padding: 13px 55px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
}
html body #root .App #settings {
  padding: 75px;
  height: 100vh;
  overflow-y: scroll;
  width: 80vw;
}
html body #root .App #settings .settings-wrapper {
  display: flex;
  flex-flow: column;
  gap: 35px;
}
html body #root .App #settings #settings-tabs {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #333333;
}
html body #root .App #settings #settings-tabs .tab {
  width: 100%;
  padding: 10px;
  font-family: "ProductSans", sans-serif;
  text-align: center;
  cursor: pointer;
}
html body #root .App #settings #settings-tabs .tab.active {
  background: #1FB5EB;
  color: white;
}
html body #root .App .required-skills {
  display: flex;
  gap: 10px;
  width: 100%;
  height: auto;
  flex-flow: column;
  margin-bottom: 10px;
}
html body #root .App .required-skills .required-skill {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  width: -moz-max-content;
  width: max-content;
}
html body #root .App .required-skills .required-skill .shareable-skill {
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 12px;
  color: #1FB5EB;
  opacity: 0.4;
  font-weight: bold;
}
html body #root .App .required-skills .required-skill .delete-required-skill {
  font-family: "ProductSans", sans-serif;
  color: red;
  font-size: 1rem;
}
html body #root .App .settings-block.days {
  display: flex;
  gap: 20px;
  flex-flow: column;
}
html body #root .App .settings-block.add {
  display: flex;
  flex-flow: column;
  gap: 10px;
}
html body #root .App .settings-block.add input:not(input[type=checkbox]) {
  height: 40px;
  border-radius: 4px;
  border: solid 1px #9B9B9B;
  padding-left: 20px;
  font-size: 0.8rem;
}
html body #root .App .settings-block.add label {
  font-size: 0.8rem;
  color: #9B9B9B;
  display: block;
}
html body #root .App .settings-block.add select {
  height: 40px;
  border-radius: 4px;
  border: solid 1px #9B9B9B;
  font-size: 0.8rem;
}
html body #root .App .settings-block.add textarea {
  height: 100px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #9B9B9B;
  padding-left: 20px;
  font-size: 0.8rem;
}
html body #root .App .settings-block.add button {
  height: 40px;
  border-radius: 4px;
  border: none;
  background: #1FB5EB;
  color: white;
  font-size: 0.8rem;
  font-family: "ProductSans", sans-serif;
  cursor: pointer;
}
html body #root .App .settings-block.add .add-required-skill-wrapper {
  display: flex;
  gap: 10px;
}
html body #root .App .settings-block.add .add-required-skill-wrapper label {
  display: initial;
}
html body #root .App .settings-block .input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
html body #root .App .settings-block .input-wrapper p {
  margin-bottom: 0;
}
html body #root .App .settings-block .input-wrapper input {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: solid 1px #9B9B9B;
  box-shadow: none;
  text-align: center;
  padding: 0 0 0 0;
  max-width: 40px;
}
html body #root .App .settings-block .activity {
  margin-bottom: 30px;
}
html body #root .App .settings-block .activity input {
  font-family: "ProductSans", sans-serif;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-bottom: 1px solid #9B9B9B;
  font-size: 1rem;
}
html body #root .App .settings-block .day-wrapper {
  display: flex;
  flex-flow: column;
  gap: 20px;
}
html body #root .App .settings-block .day-wrapper h6 {
  font-size: 0.9rem;
  margin-bottom: 0;
}
html body #root .App .settings-block .day-wrapper .day-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
html body #root .App .settings-block .day-wrapper .select-all {
  color: #1FB5EB;
  font-size: 12px;
}
html body #root .App .settings-block .day-wrapper .select-all.active {
  color: #FF5F5F;
}
html body #root .App .settings-block .day-wrapper .periods-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: stretch;
}
html body #root .App .settings-block .day-wrapper .periods-wrapper .add-another-period {
  cursor: pointer;
  color: #1FB5EB;
  font-weight: bold;
}
html body #root .App .settings-block .day-wrapper .period-wrapper {
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  color: #9B9B9B;
  cursor: pointer;
  font-family: "ProductSans", sans-serif;
  position: relative;
}
html body #root .App .settings-block .day-wrapper .period-wrapper.active {
  background: #1FB5EB;
  border-color: #1FB5EB;
  color: #FFFFFF;
}
html body #root .App .settings-block .toggle {
  margin: 15px 0;
  display: block;
  height: inherit;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}
html body #root .App .settings-block .toggle span {
  padding: 5px 20px;
  border: 1px solid #9B9B9B;
}
html body #root .App .settings-block .toggle span.active {
  background: #1FB5EB;
  border-color: #1FB5EB;
  color: white;
}
html body #root .App .settings-block .toggle span.no {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
html body #root .App .settings-block .toggle span.yes {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
html body #root .App #print-schedule {
  width: 100%;
  padding: 30px;
}
html body #root .App #print-schedule .camper {
  grid-template-columns: repeat(3, 1fr) !important;
}
html body #root .App #print-schedule .counselors-by-day-wrapper h6 {
  font-size: 10px;
}
html body #root .App #print-schedule .counselors-by-day-wrapper .counselor-row {
  padding: 0;
}
html body #root .App #print-schedule .counselors-by-day-wrapper .day {
  font-size: 1.2rem;
}
html body #root .App #print-schedule .period-wrapper h6 {
  display: inline-block;
  width: 25px;
  text-align: center;
  font-size: 15px;
}
html body #root .App #print-schedule .period-wrapper h6:first-of-type {
  width: 100px;
}
html body #root .App #print-schedule .detail-info-wrapper {
  display: block;
}
html body #root .App #print-schedule .activity-roster-wrapper {
  margin-bottom: 30px;
  position: relative;
  width: calc(50% - 15px);
  display: block;
}
html body #root .App #print-schedule .activity-roster-wrapper.schedule {
  page-break-after: always;
  position: relative !important;
}
html body #root .App #print-schedule .activity-roster-wrapper.clearfix {
  clear: both;
}
html body #root .App #print-schedule .activity-roster-wrapper .top-row h6 {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
}
html body #root .App #print-schedule .activity-roster-wrapper .top-row h6.day {
  color: #333333;
  text-transform: capitalize;
}
html body #root .App #print-schedule .activity-roster-wrapper .top-row h6.period {
  color: #9B9B9B;
}
html body #root .App #print-schedule .activity-roster-wrapper .top-row h6.activity {
  color: #1FB5EB;
}
html body #root .App #print-schedule .activity-roster-wrapper .activity-roster .camper-row .camper-column, html body #root .App #print-schedule .activity-roster-wrapper .activity-roster .camper-row .empty-column {
  display: table-cell;
  text-align: center;
  line-height: normal;
  padding: 3px;
  border: 1px solid black;
  box-sizing: border-box;
  width: 25px;
}
html body #root .App #print-schedule .activity-roster-wrapper .activity-roster .camper-row .camper-column h6, html body #root .App #print-schedule .activity-roster-wrapper .activity-roster .camper-row .empty-column h6 {
  margin: 0;
  font-size: 11px;
}
html body #root .App #print-schedule .activity-roster-wrapper .activity-roster .camper-row .camper-column:first-of-type, html body #root .App #print-schedule .activity-roster-wrapper .activity-roster .camper-row .empty-column:first-of-type {
  width: 100px;
}
html body #root .App #schedule {
  margin-top: 20px;
}
html body #root .App #schedule .campers-wrapper, html body #root .App #schedule .counselors-wrapper {
  display: flex;
  flex-flow: column;
  gap: 15px;
  width: 100%;
  margin-top: 30px;
}
html body #root .App #schedule .campers-wrapper .camper, html body #root .App #schedule .campers-wrapper .counselor, html body #root .App #schedule .counselors-wrapper .camper, html body #root .App #schedule .counselors-wrapper .counselor {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  width: 100%;
}
html body #root .App #schedule .campers-wrapper .camper select, html body #root .App #schedule .campers-wrapper .counselor select, html body #root .App #schedule .counselors-wrapper .camper select, html body #root .App #schedule .counselors-wrapper .counselor select {
  width: calc(100% - 100px);
  padding: 0;
  border: unset;
  border-bottom: 1px solid #9B9B9B;
}
html body #root .App #schedule .campers-wrapper .camper select.missing, html body #root .App #schedule .campers-wrapper .counselor select.missing, html body #root .App #schedule .counselors-wrapper .camper select.missing, html body #root .App #schedule .counselors-wrapper .counselor select.missing {
  border: 2px solid #FF5F5F;
  border-radius: 5px;
  color: #FF5F5F;
}
html body #root .App #schedule .campers-wrapper .camper h2, html body #root .App #schedule .campers-wrapper .counselor h2, html body #root .App #schedule .counselors-wrapper .camper h2, html body #root .App #schedule .counselors-wrapper .counselor h2 {
  text-align: left;
  width: 100%;
  border-bottom: 2px solid;
  padding-bottom: 7px;
}
html body #root .App #schedule .campers-wrapper .camper h6, html body #root .App #schedule .campers-wrapper .counselor h6, html body #root .App #schedule .counselors-wrapper .camper h6, html body #root .App #schedule .counselors-wrapper .counselor h6 {
  font-size: 16px;
  margin-bottom: 0;
  padding: 3.1px;
}
html body #root .App #schedule .campers-wrapper .camper .activity-block, html body #root .App #schedule .campers-wrapper .counselor .activity-block, html body #root .App #schedule .counselors-wrapper .camper .activity-block, html body #root .App #schedule .counselors-wrapper .counselor .activity-block {
  display: flex;
  width: 100%;
  position: relative;
}
html body #root .App #schedule .campers-wrapper .camper .activity-wrapper, html body #root .App #schedule .campers-wrapper .counselor .activity-wrapper, html body #root .App #schedule .counselors-wrapper .camper .activity-wrapper, html body #root .App #schedule .counselors-wrapper .counselor .activity-wrapper {
  text-align: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
}
html body #root .App #schedule .campers-wrapper .camper .activity-wrapper:first-of-type, html body #root .App #schedule .campers-wrapper .counselor .activity-wrapper:first-of-type, html body #root .App #schedule .counselors-wrapper .camper .activity-wrapper:first-of-type, html body #root .App #schedule .counselors-wrapper .counselor .activity-wrapper:first-of-type {
  text-align: right;
  padding-right: 25px;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  left: -55px;
  top: 0;
  height: 100%;
}
html body #root .App #schedule .campers-wrapper .camper .activity-wrapper:first-of-type:after, html body #root .App #schedule .campers-wrapper .counselor .activity-wrapper:first-of-type:after, html body #root .App #schedule .counselors-wrapper .camper .activity-wrapper:first-of-type:after, html body #root .App #schedule .counselors-wrapper .counselor .activity-wrapper:first-of-type:after {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  width: 2px;
  height: 100%;
  background: #9B9B9B;
}
html body #root .App #schedule .campers-wrapper .camper .activity-wrapper.days, html body #root .App #schedule .campers-wrapper .counselor .activity-wrapper.days, html body #root .App #schedule .counselors-wrapper .camper .activity-wrapper.days, html body #root .App #schedule .counselors-wrapper .counselor .activity-wrapper.days {
  display: flex;
  flex-flow: column;
  gap: 5px;
}
html body #root .App #schedule .campers-wrapper .camper .activity-wrapper.days h6:nth-child(even), html body #root .App #schedule .campers-wrapper .counselor .activity-wrapper.days h6:nth-child(even), html body #root .App #schedule .counselors-wrapper .camper .activity-wrapper.days h6:nth-child(even), html body #root .App #schedule .counselors-wrapper .counselor .activity-wrapper.days h6:nth-child(even) {
  background: #e7e7e7;
}
html body #root .App #schedule .campers-wrapper .camper .day, html body #root .App #schedule .campers-wrapper .counselor .day, html body #root .App #schedule .counselors-wrapper .camper .day, html body #root .App #schedule .counselors-wrapper .counselor .day {
  width: 100%;
}
html body #root .App #schedule .campers-wrapper .camper .day h6:nth-child(even), html body #root .App #schedule .campers-wrapper .counselor .day h6:nth-child(even), html body #root .App #schedule .counselors-wrapper .camper .day h6:nth-child(even), html body #root .App #schedule .counselors-wrapper .counselor .day h6:nth-child(even) {
  background: #e7e7e7;
}
html body #root .App #schedule .campers-wrapper .counselor, html body #root .App #schedule .counselors-wrapper .counselor {
  gap: 10px;
  row-gap: 20px;
}
html body #root .App #scheduler #date-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
html body #root .App #scheduler #date-wrapper button {
  position: relative;
  background: transparent;
  border: 0;
  cursor: pointer;
}
html body #root .App #scheduler #date-wrapper button h6 {
  color: #FFFFFF;
  background: #FF5F5F;
  font-size: 0.9rem;
  padding: 5px 20px;
  border-radius: 10px;
  font-weight: 400;
  box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
}
html body #root .App #scheduler .tab-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
html body #root .App #scheduler .tab-wrapper .tab {
  width: 50%;
  height: 45px;
  font-size: 18px;
  font-family: "ProductSans", sans-serif;
  background: #9B9B9B;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
html body #root .App #scheduler .tab-wrapper .tab.active {
  background: #1FB5EB;
  color: white;
}
html body #root .App #scheduler .schedule-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
html body #root .App #scheduler .schedule-wrapper #schedule-search {
  width: 300px;
  height: 40px;
  margin-bottom: 15px;
}
html body #root .App #scheduler .schedule-wrapper button {
  position: relative;
  background: transparent;
  border: 0;
  cursor: pointer;
}
html body #root .App #scheduler .schedule-wrapper button h6 {
  color: #FFFFFF;
  background: #FF5F5F;
  font-size: 0.9rem;
  padding: 5px 20px;
  border-radius: 10px;
  font-weight: 400;
  box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
}
html body #root .App #scheduler .filters-wrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
}
html body #root .App #scheduler .filters-wrapper .filter-wrapper {
  width: 100%;
}
html body #root .App #scheduler .filters-wrapper .filter-wrapper input[type=checkbox], html body #root .App #scheduler .filters-wrapper .filter-wrapper label {
  cursor: pointer;
}
html body #root .App #scheduler .filters-wrapper .filter-wrapper.days li {
  width: 30% !important;
}
html body #root .App #scheduler ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  margin-bottom: 20px;
}
html body #root .App #scheduler ul li {
  width: 20%;
}
html body #root .App #scheduler .activities-schedule {
  display: flex;
  flex-flow: column;
  gap: 50px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity-name {
  color: #1FB5EB;
}
html body #root .App #scheduler .schedule-activity-wrapper .camper-cabin {
  color: #9B9B9B;
  font-size: 14px;
  margin-bottom: 5px;
}
html body #root .App #scheduler .schedule-activity-wrapper .camper-number {
  color: #FF5F5F;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity {
  display: flex;
  flex-flow: column;
  gap: 50px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period {
  display: flex;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .column {
  margin-left: 50px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .period-wrapper p, html body #root .App #scheduler .schedule-activity-wrapper .activity .period .period-wrapper .cabin {
  font-size: 15px;
  font-weight: 300;
  font-family: "ProductSans", sans-serif;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .period-wrapper p {
  margin-bottom: 5px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .period-wrapper .cabins {
  font-weight: bold;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .campers {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  justify-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  width: 100%;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .campers .individual-camper {
  width: 100%;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .campers .individual-camper .name {
  display: flex;
  gap: 3px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .campers .individual-camper .name h6 {
  font-size: 16px;
  margin-bottom: 3px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors {
  display: flex;
  flex-flow: column;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors .counselor-day {
  font-size: 20px;
  color: #1FB5EB;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors h6 {
  font-size: 15px;
  margin-bottom: 3px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors .individual-counselor {
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 3px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors .individual-counselor .period-role, html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors .individual-counselor .name {
  display: flex;
  gap: 4px;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors .individual-counselor .period-role h6 {
  color: #FF5F5F;
}
html body #root .App #scheduler .schedule-activity-wrapper .activity .period .counselors .period-counselors-wrapper {
  display: flex;
  gap: 20px;
}
html body #root .App #scheduler .warnings-wrapper {
  margin: 30px 0;
}
html body #root .App #scheduler .warnings-wrapper.campers {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  justify-items: center;
}
html body #root .App #scheduler .warnings-wrapper.counselors {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-items: center;
}
html body #root .App #scheduler .warnings-wrapper.batch {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}
html body #root .App #scheduler .warnings-wrapper.batch h6 {
  font-size: 22px;
  color: #FF5F5F !important;
  margin-bottom: 5px;
}
html body #root .App #scheduler .warnings-wrapper.batch p {
  font-size: 16px;
  margin-bottom: 0 !important;
}
html body #root .App #scheduler .warnings-wrapper .warning {
  width: 100%;
}
html body #root .App #scheduler .warnings-wrapper .warning.camper h6 {
  font-size: 16px;
  color: #333333;
}
html body #root .App #scheduler .warnings-wrapper .warning.camper .periods {
  display: flex;
  gap: 5px;
}
html body #root .App #scheduler .warnings-wrapper .warning.camper .periods h6 {
  font-size: 14px;
  color: #FF5F5F;
}
html body #root .App #scheduler .warnings-wrapper .warning.camper p {
  margin: 0 !important;
}
html body #root .App #scheduler .warnings-wrapper .warning.activity h6 {
  font-size: 16px;
  color: #333333;
}
html body #root .App #scheduler .warnings-wrapper .warning.activity .day-title {
  text-transform: capitalize;
}
html body #root .App #scheduler .warnings-wrapper .warning.activity .periods {
  display: flex;
  flex-flow: column;
  gap: 5px;
}
html body #root .App #scheduler .warnings-wrapper .warning.activity .periods .period {
  display: flex;
  gap: 5px;
}
html body #root .App #scheduler .warnings-wrapper .warning.activity .periods .period .period-title {
  color: #1FB5EB;
}
html body #root .App #scheduler .warnings-wrapper .warning.activity .periods .period .roles {
  display: flex;
  gap: 5px;
}
html body #root .App #scheduler .warnings-wrapper .warning.activity .periods h6 {
  font-size: 14px;
  color: #FF5F5F;
}/*# sourceMappingURL=style.css.map */