/************************************************
Breakpoints
************************************************/
@mixin breakpoint($class) {
	@if $class==xxs {
		@media (max-width: 400px) {
			@content;
		}
	}

	@else if $class==xs {
		@media (max-width: 425px) {
			@content;
		}
	}

	@else if $class==sm {
		@media (max-width: 576px) {
			@content;
		}
	}

	@else if $class==md {
		@media (max-width: 768px) {
			@content;
		}
	}

	@else if $class==lg {
		@media (max-width: 991px) {
			@content;
		}
	}

	@else if $class==xl {
		@media (max-width: 1200px) {
			@content;
		}
	}

	@else if $class==mair {
		@media (max-width: 1350px) {
			@content;
		}
	}

	@else if $class==xxl {
		@media (max-width: 1600px) {
			@content;
		}
	}

	@else {
		@warn "Breakpoint mixin supports: sm, md, lg, xxl";
	}
}

/************************************************
Printer Formatting
************************************************/
@media print {
	// .schedule {
	// 	page-break-after: always !important;
	// 	break-after: page !important;
	// }

	// .last-schedule {
	// 	page-break-after: avoid;
	// }
	// .period-wrapper, .detail-info-wrapper, .top-row, .activity-roster .camper-row, .camper-column, .empty-column {
	// 	display: block !important;
	// 	width: 100% !important;
	// }
}
 

/************************************************
Colors
************************************************/
$white: #FFFFFF;
$dark-charcoal: #333333;
$button-blue: #1FB5EB;
$spanish-gray: #9B9B9B;
$dark-silver: #707070;
$cultured-white-sidebar: #F5F8FA;
$cultured-white-notes: #F9FAF5;
$pastel-red: #FF5F5F;

/************************************************
Imports
************************************************/
@font-face {
    font-family: 'ProductSans';
    src: url('/src/assets/fonts/Product_Sans_400.ttf'); //or .ttf
}

$product-sans: 'ProductSans', sans-serif;

/************************************************
Browser Resets
************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
rem,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
rembed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}

/************************************************
Older Browser Resets
************************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/************************************************
ADA Page Tabbing
************************************************/
button:focus,
input:focus,
a:focus,
textarea:focus,
select:focus {
	outline: none !important;
	outline-style: none !important;
	border-color: inherit !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

/************************************************
ADA User Tabbing
************************************************/
body.user-is-tabbing *:focus {
	outline: 2px solid #7AACFE !important;
	/* for non-webkit browsers */
	outline: 5px auto -webkit-focus-ring-color !important;
}

/************************************************
HTML
************************************************/
* {
	box-sizing: border-box;
}
html {
	max-width: 100%;
	scroll-behavior: smooth;

	&.no-scroll {
		overflow: hidden;
	}

	font-size: 25px; //default

	@include breakpoint(xxl) {
		font-size: 20px;
	}

	@include breakpoint(xl) {
		font-size: 18px;
	}


	/************************************************
	Body
	************************************************/
	body {
		overflow-x: hidden;
		max-width: 100%;
		height: auto;

        /************************************************
		Font Sizing
		************************************************/
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 600;
			font-family: $product-sans;
			margin-bottom: 15px;
		}

		p,
		a,
		span,
		ul,
		ol,
		li {
			font-weight: 300;
			font-family: $product-sans;
		}

		a {
			// color: white;
			text-decoration: none;
			// transition: color .4s ease-in-out;

			// &:hover {
			// 	color: $button-blue;
			// }

			&:visited {
                color: $button-blue;
				// &:hover {
				// 	color: $button-blue;
				// }
			}
		}

		h1, h2, h3, h4, h5, h6 {
			a {
				font-size: inherit;
			}
		}

		h1 {
			font-size: 2.5rem;

			@include breakpoint(xxl) {
				font-size: 1.8rem;
			}
		}

		h2 {
			font-size: 2.25rem;

			@include breakpoint(md) {
				font-size: 2rem;
			}
		}

		h3 {
			font-size: 2rem;

			@include breakpoint(md) {
				font-size: 1.8rem;
			}
		}

		h4 {
			font-size: 1.75rem;

			@include breakpoint(md) {
				font-size: 1.5rem;
			}
		}

		h5 {
			font-size: 1.5rem;

			@include breakpoint(md) {
				font-size: 1.2rem;
			}
		}

		h6 {
			font-size: 1.25rem;
		}

		p,
		ul,
		li,
		ol,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $dark-charcoal;
		}

		p,
		a,
		ul,
		ol,
		li {
			font-size: 1rem;
		}

		p {
			line-height: 1.25;
			margin-bottom: 15px;

			strong {
				font-weight: bold;
			}
		}

        #root {
            .App {
                display: flex;
				justify-content: flex-end;
				&.print-schedule {
					display: block;
				}
				/************************************************
				 * Global
				************************************************/
				.top-preference {
					font-size: 14px;
					color: $pastel-red;
				}
				label {
					font-family: $product-sans;
					font-size: .9rem;
				}
				.minus {
					position: absolute;
					top: -8px;
					right: -8px;
					background: $pastel-red;
					color: white;
					width: 20px;
					height: 20px;
					font-size: 15px;
					text-align: center;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.campers-wrapper {
					display: flex;
					flex-flow: column;
					gap: 15px;
					width: 100%;
					margin-top: 30px;
					.camper {
						display: grid;
						grid-auto-rows: minmax(min-content, max-content);
						grid-template-columns: repeat(2, 1fr);
						justify-items: center;
						width: fit-content;
						height: auto;
						width: 100%;

						h2 {
							text-align: left;
							width: 100%;
							border-bottom: 2px solid;
							padding-bottom: 7px;
							font-size: 1.1rem;
						}
						h6 {
							font-size: 16px;
							margin-bottom: 0;
							padding: 5px;
						}
						.activity-block {
							display: flex;
							width: 100%;
							position: relative;
						}
						.activity-wrapper {
							text-align: left;
							width: 100%;
							position: relative;
							flex-flow: column;
							&:first-of-type {
								text-align: right;
								padding-right: 17px;
								width: max-content;
								position: absolute;
								left: -35px;
								top: 0;
								height: 100%;
								&:after {
									content: '';
									position: absolute;
									top: 0;
									right: 15px;
									width: 2px;
									height: 100%;
									background: $spanish-gray;
								}
							}
							&.days {
								h6 {
									&:nth-child(even) {
										background: #e7e7e7;
									}
								}
							}
						}
						.day {
							width: 100%;
							h6 {
								&:nth-child(even) {
									background: #e7e7e7;
								}
							}
						}
					}
				}

				.printer {
					height: 35px;
					width: 35px;
					margin-bottom: 15px;
					cursor: pointer;
				}

				.counselors-wrapper {
					display: flex;
					flex-flow: column;
					width: 100%;
					margin-top: 30px;
					gap: 30px;
					.counselor-wrapper {
						.name {
							color: $button-blue;
						}
						.activity-name {
							color: $dark-charcoal;
							opacity: .8;
						}
						.role {
							color: $pastel-red;
							font-weight: bold;
							opacity: .8;
						}
						.period-off, .unavailable {
							font-weight: 700;
						}
						.period-off {
							color: $button-blue;
						}
						.unavailable {
							color: $pastel-red;
						}
						.day {
							text-transform: capitalize;
						}
					}
					.counselor {
						display: grid;
						grid-auto-rows: minmax(min-content, max-content);
						grid-template-columns: repeat(3, 1fr);
						justify-items: center;
						width: fit-content;
						height: auto;
						width: 100%;
						h2 {
							text-align: left;
							width: 100%;
							border-bottom: 2px solid;
							padding-bottom: 7px;
							font-size: 1.1rem;
						}
						h6 {
							font-size: 16px;
							margin-bottom: 0;
							padding: 5px;
						}
						.day {
							width: 100%;
							h6 {
								&:nth-child(even) {
									background: #e7e7e7;
								}
							}
						}
					}
					.counselor {
						gap: 10px;
						row-gap: 20px;
					}
				}

				.required-cabins-wrapper {
					display: grid;
					grid-auto-rows: minmax(min-content, max-content);
					grid-template-columns: repeat(5, 1fr);
					grid-gap: 10px;
					justify-items: center;
					width: fit-content;
					height: auto;
					.cabin-wrapper {
						padding: 5px 10px;
						font-size: .9rem;
						border-radius: 4px;
						border: 1px solid $spanish-gray;
						color: $spanish-gray;
						cursor: pointer;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						&.active {
							background: $button-blue;
							border-color: $button-blue;
							color: $white;
						}
					}
				}

				.counselors-by-day-wrapper {
					display: flex;
					flex-flow: column;
					.day {
						font-size: 1.5rem;
						font-weight: bold;
						color: $button-blue;
						font-family: $product-sans;
						margin-bottom: 15px;
						text-transform: capitalize;
						margin-top: 30px;
					}
					.name {
						font-weight: 800 !important;
					}
					.counselor-row {
						display: grid;
						grid-auto-rows: minmax(min-content, max-content);
						grid-template-columns: repeat(6, 1fr);
						justify-items: center;
						align-items: center;
						text-align: center;
						padding: 10px 5px;

						&:nth-child(even) {
							background-color: #efefef;
						}

						h6 {
							font-size: 16px;
							font-weight: 300;
							color: $dark-charcoal;
							font-family: $product-sans;
							margin-bottom: 0;
						}
						.unavailable {
							color: $button-blue;
						}
						.period-off {
							color: $button-blue;
						}
						.missing {
							color: $pastel-red;
						}
					}
				}

				.available-periods-wrapper, .available-days-wrapper {
					display: grid;
					grid-auto-rows: minmax(min-content, max-content);
					grid-template-columns: repeat(5, 1fr);
					grid-gap: 20px;
					justify-items: center;
					width: fit-content;
					height: auto;
					.period-wrapper {
						padding: 10px 20px;
						font-size: .9rem;
						border-radius: 4px;
						border: 1px solid $spanish-gray;
						color: $spanish-gray;
						cursor: pointer;
						&.active {
							background: $button-blue;
							border-color: $button-blue;
							color: $white;
						}
					}
				}
				.available-days-wrapper {
					grid-template-columns: repeat(4, 1fr);
					.period-wrapper {
						width: 100%;
						text-align: center;
					}
				}

				.attribute-values {
					display: grid;
					gap: 5px;
					grid-template-columns: repeat(5, 1fr);
					h6 {
						margin-bottom: 0;
					}
					.attribute-value-btn {
						padding: 2px 10px;
						border: solid 1px $spanish-gray;
						border-radius: 10px;
						font-size: .8rem;
						cursor: pointer;
						position: relative;
						&.active {
							background: $button-blue;
							border-color: $button-blue;
							color: $white;
						}
					}
				}
				.add-cabin {
					font-family: $product-sans;
					margin-top: 10px;
					cursor: pointer;
				}

				.btn-wrapper {
					display: flex;
					gap: 10px;
				}
				.add-btn {
					font-family: $product-sans;
					padding: 10px;
					background: $button-blue;
					color: white;
					border-radius: 4px;
					border: none;
					cursor: pointer;
				}
				.delete-btn {
					font-family: $product-sans;
					padding: 10px;
					background: $pastel-red;
					color: white;
					border-radius: 4px;
					border: none;
					cursor: pointer;
				}
				button.delete {
					position: relative;
					margin-top: 50px;
					margin-left: 20px;
					background: $pastel-red;
					color: white;
					border: none;
					border-radius: 4px;
					padding: 10px;
					font-size: .9rem;
					cursor: pointer;
				}

				///temp
				#scheduler, #reports {
					width: 100%;
					padding: 50px;
					max-width: 80%;
					display: flex;
					flex-flow: column;
					h1 {
						font-size: 1.3rem;
					}
					h2 {
						font-size: 1.1rem;
					}
				}

				#reports {
					.btn {
                        position: relative;
						background: transparent;
						border: 0;
						cursor: pointer;

                        h6 {
                            color: $white;
                            background: $pastel-red;
                            font-size: .9rem;
                            padding: 13px 55px;
                            border-radius: 15px;
                            font-weight: 400;
							//convert $pastel-red hex to rgba
							//rgba(255, 0, 0, 0.1)
							box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
                        }
                    }
					.cabin-wrapper {
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 30px;
					}
					.schedule-types {
						margin-bottom: 30px;
						display: flex;
						flex-flow: column;
						gap: 5px;
					}
				}

				.cabin-groups {
					display: flex;
					flex-flow: column;
					gap: 10px;
					margin-bottom: 20px;
					select {
						padding: 10px;
						border-radius: 4px;
						border: 1px solid $spanish-gray;
						font-size: .9rem;
						font-family: $product-sans;
						width: 100%;
					}
				}

				.activity-preferences {
					display: flex;
					flex-flow: column;
					gap: 10px;
					margin-bottom: 20px;
					.btn-wrapper {
						margin: 10px 0;
					}
					button {
						padding: 7px;
						border-radius: 4px;
						border: none;
						font-size: 14px;
						font-family: $product-sans;
						cursor: pointer;
						background: $button-blue;
						color: white;
					}
				}

				.location-wrapper {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
				}

				.cabin-wrapper {
					display: flex;
					gap: 10px;
					button {
						padding: 5px 7px;
						border-radius: 4px;
						border: 1px solid $spanish-gray;
						font-size: .9rem;
						font-family: $product-sans;
						background: transparent;
						pointer-events: none;
					}
				}

                .sidebar {
                    padding-top: 45px;
                    width: 380px;
					max-width: 20%;
                    height: 100vh;
                    background: $cultured-white-sidebar;
                    position: fixed;
					top: 0;
					left: 0;
					@include breakpoint(xxl) {
						padding-top: 20px;
					}

                    .sidebar-top {
                        display: flex;
                        align-items: center;
						padding-left: 30px;
						margin-bottom: 20px;
						flex-flow: column;
						gap: 10px;
						.logo {
							width: 75px;
							height: 75px;
							object-fit: contain;
							border-radius: 50%;
							@include breakpoint(xxl) {
								width: 50px;
								height: 50px;
							}
							@include breakpoint(xl) {
								width: 40px;
								height: 40px;
							}
						}
                        .user-info {
                            text-align: left;
							@include breakpoint(xxl) {
								text-align: center
							}
                            .name {
                                color: $button-blue;
								@include breakpoint(xxl) {
									margin-bottom: 0;
								}
                            }

                            .email {
                                font-size: .72rem;
                                margin-bottom: 0;
                            }
							.signout-btn {

							}
                        }

						.schedule-control {
							position: relative;
							display: flex;
							align-items: center;
							gap: 15px;

							select {
								padding: 10px;
								border-radius: 4px;
								border: 1px solid #9B9B9B;
								font-size: 0.9rem;
								font-family: "ProductSans", sans-serif;
								width: 100%;

							}

							.dupe-btn {
								position: relative;
								color: $button-blue;
								border: none;
								background: transparent;
								cursor: pointer;
								font-size: 1.5rem;
								&[disabled] {
									opacity: .5;
									pointer-events: none;
								}
							}
						}
                    }

                    .sidebar-links {
                        .link {
                            display: flex;
                            align-items: center;
                            gap: 25px;
                            padding: 25px 60px;

                            h6 {
                                font-weight: 400;
                                font-size: 1.04rem;
                                margin-bottom: 0;
                            }

                            img {
                                object-fit: contain;
                                height: 100%;
                                opacity: .6;


                                &.campers {
                                    width: 25px;
                                }

                                &.counselors {
                                    width: 22px;
                                }

                                &.activities {
                                    width: 25px;
                                }

                                &.scheduler {
                                    width: 21.5px;
                                }

                                &.reports {
                                    width: 21px;
                                }
                            }

                            &.active {
                                border-left: 10px solid $button-blue;
                                background: $white;
                                padding-left: 50px;


                              h6 {
                                font-weight: 700;
                              }

                              img {
                                opacity: 1;

                                &.counselors {
                                    path {
                                        fill: $button-blue;
                                    }
                                }
                              }
                            }
                        }
                    }

					.signout-btn {
						position: relative;
						left: 50%;
                        transform: translateX(-50%);
						background: transparent;
						border: 0;
						cursor: pointer;

						h6 {
                            color: $white;
                            background: $pastel-red;
                            font-size: .9rem;
                            padding: 5px 20px;
                            border-radius: 10px;
                            font-weight: 400;
							//convert $pastel-red hex to rgba
							//rgba(255, 0, 0, 0.1)
							box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
                        }
					}

                    .btn {
                        position: absolute;
                        bottom: 40px;
                        left: 50%;
                        transform: translateX(-50%);
						@include breakpoint(xxl) {
							bottom: 20px;
						}

                        h6 {
                            color: $white;
                            background: $pastel-red;
                            font-size: .9rem;
                            padding: 13px 55px;
                            border-radius: 15px;
                            font-weight: 400;
							//convert $pastel-red hex to rgba
							//rgba(255, 0, 0, 0.1)
							box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
                        }
                    }
                }
				.main-content {
					display: flex;
				}
				.search-wrapper {
					width: 580px;
					max-width: 30vw;
                    height: 100vh;
                    background: $cultured-white-notes;
					padding: 50px 30px;
					position: relative;
					input:not(input[type='checkbox']) {
						position: relative;
						width: 100%;
						margin-bottom: 15px;
						height: 45px;
						border-radius: 13px;
						box-shadow: none;
						border: none;
						padding-inline-start: 30px;
						font-size: 1rem;
						font-family: $product-sans;
						&::placeholder {
							color: $spanish-gray;
						}
					}
					#bulk-upload {
						padding: 5px 10px;
						background: white;
						color: $dark-silver;
						font-size: 1rem;
						border-radius: 50px;
						cursor: pointer;
					}
					#bulk-upload-background {
						width: 100%;
						height: 100%;
						position: fixed;
						top: 0;
						left: 0;
						background: rgba(0, 0, 0, 0.4);
						z-index: 8;
					}
					#bulk-upload-window {
						width: 70vw;
						height: 70vh;
						background: white;
						box-shadow: 0px 20px 10px -17px rgba(0, 0, 0, 0.4);
						position: fixed;
						z-index: 9;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						padding: 30px;
						display: flex;
						justify-content: space-between;
						flex-flow: column;
						overflow: scroll;
						.top-bar {
							width: 100%;
							display: flex;
							justify-content: space-between;
						}
						#file {
							width: 100%;
							height: 50vh;
							border: 1px dashed $button-blue;
							padding: 30px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 30px;
							border-radius: 15px;
							flex-flow: column;
							#file-name {
								color: $pastel-red;
							}
						}
						.upload {
							background: $button-blue;
							border: unset;
							border-radius: 7px;
							height: 45px;
							width: 300px;
							position: relative;
							left: 50%;
							transform: translate(-50%);
							color: white;
							cursor: pointer;
							pointer-events: all;

							&.disabled {
								pointer-events: none;
								opacity: .5;
							}
						}
					}
					.filter-wrapper {
						display: flex;
						justify-content: space-between;
						font-family: $product-sans;
						.filter-menu {
							width: 580px;
							max-width: 30vw;
							z-index: 9;
							position: absolute;
							background: gray;
						}
						.filter-icons {
							display: flex;
							justify-content: space-between;
							gap: 5px;
							#sort-by, #filter-by {
								width: 28px;
								height: 28px;
								object-fit: contain;
							}
						}
					}
				}
				.search-results {
					display: flex;
					flex-flow: column;
					gap: 15px;
					margin-top: 20px;
					height: calc(100% - 67px);
					overflow-y: scroll;
					.search-card {
						padding: 30px;
						background: white;
						border-radius: 35px;
						text-align: left;
						display: flex;
						flex-flow: column;
						justify-content: space-between;
						gap: 15px;
						cursor: pointer;
						position: relative;
						.name-wrapper {
							display: flex;
							gap: 5px;
							h6 {
								margin-bottom: 0;
								font-size: .9rem;
							}
						}
						.periods-title {
							font-size: 1rem;
						}
						p {
							margin-bottom: 0;
						}
						.notes {
							font-size: .9rem;
							color: $spanish-gray;
						}
						.special-activities {
							font-size: .9rem;
							color: $button-blue;
						}
						.periods-list {
							display: flex;
							flex-flow: row wrap;
							gap: 5px;
							align-items: center;
							h6 {
								font-size: .9rem;
								margin-bottom: 0;
							}
							.period {
								font-size: .9rem;
							}
						}
					}
				}
				.name-info-wrapper {
					display: flex;
					gap: 5px;
					justify-content: space-between;
					&.vertical {
						flex-flow: column;
					}
					.location {
						font-size: .8rem;
						margin-bottom: 0;
					}
					.name-input {
						border: unset;
						padding-left: 0;
						margin-left: 0;
						width: 48%;
					}
					.name-input, .title {
						font-size: 1.2rem;
						font-weight: bold;
						font-family: "ProductSans", sans-serif;
					}
					.name {
						margin-bottom: 5px;
					}
					.title {
						font-weight: 400;
						margin-bottom: 0;
					}
					p {
						margin-bottom: 0;
					}
					.is-admin-checkbox {
						margin-top: 10px;
					}
					.is-admin {
						font-size: .9rem;
					}
				}
				.detailed-info {
					width: 50vw;
					height: 100vh;
					padding: 50px 30px;
					font-family: $product-sans;
					overflow-y: scroll;
					.edit {
						font-size: .8rem;
						color: $spanish-gray
					}
					.nav {
						display: flex;
						justify-content: space-between;
						margin-bottom: 50px;
						.info-count {
							width: 100%;
							display: flex;
							justify-content: flex-end;
						}
						.info-count-numbers {
							display: flex;
							justify-content: space-between;
							gap: 10px;
							.info-arrows {
								display: flex;
								justify-content: space-between;
								gap: 5px;
								.arrow-left, .arrow-right {
									width: 28px;
									height: 28px;
									object-fit: contain;
									cursor: pointer;
								}
								.arrow-left {
									transform: rotate(180deg);
									&.first {
										opacity: .5;
										cursor: default;
									}
								}
							}
						}
					}
					.info-wrapper {
						display: flex;
						flex-flow: column;
						gap: 30px;
						padding: 0 75px;
						@include breakpoint(mair) {
							padding: 0 25px;
						}

						.detail-info-wrapper {
							h5 {
								font-size: 1.2rem;
								font-weight: bold !important;
							}
							h5,h6 {
								font-weight: 400;
							}

							.counselor {
								grid-template-columns: repeat(2, 1fr);
							}

							.title-wrapper {
								display: flex;
								justify-content: space-between;
								align-items: center;

								img {
									margin-bottom: 15px
								}
							}
						}
						.skills-wrapper, .preferences-wrapper, .days-wrapper, .certifications-wrapper, .counseler-skills-wrapper, .attributes-wrapper, .requirements-wrapper {
							h6, .column-title, .column-value {
								font-size: .8rem;
								color: $spanish-gray;
								font-weight: 400;
							}
						}
						.name-info-wrapper {
							display: flex;
							justify-content: space-between;
						}
						.requirements-wrapper {
							display: flex;
							gap: 6%;
							.column {
								display: flex;
								flex-flow: column;
								gap: 7px;
								.column-title {
									font-weight: bold;
								}
								label {
									font-family: $product-sans;
									font-size: .9rem;
								}
								input {
									width: 20px;
									height: 20px;
									cursor: pointer;
									&:checked {
										accent-color: $button-blue;
										color: white;
									}
								}
								.checkboxes-wrapper {
									display: grid;
									//grid-auto-rows: minmax(min-content, max-content);
									grid-template-rows: repeat(7, 1fr);
									grid-template-columns: repeat(3, 1fr);
									grid-auto-flow: column;
									grid-gap: 10px;
									width: 100%;
									height: auto;
								}
								.checkbox-wrapper {
									display: flex;
									align-items: center;
									gap: 10px;
								}
							}
						}
						.attributes-wrapper {
							margin-bottom: 30px;
							gap: 20px;
							display: flex;
							flex-flow: column;
							h6 {
								margin-bottom: 15px;
							}
							.attribute-wrapper {
								display: flex;
								flex-flow: column;
								gap: 20px;
								select {
									padding: 10px;
									border-radius: 4px;
									border: 1px solid #9B9B9B;
									font-size: 0.9rem;
									font-family: "ProductSans", sans-serif;
									width: 100%;
								}
								.groups-change {
									display: flex;
									gap: 10px;
									align-items: center;

									.add-another-group, .remove-group {
										cursor: pointer;
										color: #1FB5EB;
										font-weight: bold;
									}
								}
								&.shareable {
									margin-top: 10px;
									flex-flow: row;
									gap: 10px;
									label {
										font-size: .9rem;
									}
								}
								&.location, &.min-max-kids {
									display: flex;
									flex-flow: row;
									align-items: center;
									h6 {
										margin-bottom: 0;
									}
									input {
										border-left: none;
										border-right: none;
										border-top: none;
										border-bottom: solid 1px $spanish-gray;
										box-shadow: none;

									}
								}
								&.min-max-kids {
									input {
										text-align: center;
										padding: 0 0 0 0;
										max-width: 40px;
									}
								}
								&.batch-by {
									display: flex;
									flex-flow: row;
									align-items: center;
									h6 {
										margin-bottom: 0;
									}
									.css-b62m3t-container {
										font-size: 16px;
									}
								}
								&.requirements {
									display: flex;
									gap: 15px;
									flex-flow: row;
									h6 {
										margin-bottom: 0;
										display: flex;
									}
									.requirement-buttons-wrapper {
										display: flex;
										flex-flow: row;
										justify-content: flex-start;
										gap: 15px;
										.attribute-value-btn {
											width: max-content;
										}
									}
								}
							}
						}
						.certifications-wrapper {
							p {
								font-size: .9rem;
								margin-bottom: 0;
								color: $spanish-gray;
							}
						}
						.skills-wrapper, .counseler-skills-wrapper {
							display: flex;
							justify-content: space-between;
							flex-flow: column;
							max-width: fit-content;
							.skill-type {
								margin-bottom: 10px;
								font-size: .9rem;
								color: $spanish-gray;
							}
							.box {
								height: 15px;
								width: 15px;
								border: solid 1px $spanish-gray;
								margin-right: 15px;
							}
							.skill {
								display: flex;
								justify-content: space-between;
								gap: 80px;
								h6 {
									font-size: .9rem;
									color: $spanish-gray;
								}
							}
						}
						.preferences-wrapper {
							display: flex;
							flex-wrap: wrap;
							width: 100%;
							gap: 6%;
							.preference {
								flex: 47%;
								display: flex;
								justify-content: space-between;
								max-width: 275px;
								h6 {
									font-size: .9rem;
									color: $spanish-gray;
								}
							}
						}
						.days-wrapper {
							display: flex;
							justify-content: flex-start;
							gap: 6%;
							.day {
								width: 250px;
							}
							h6 {
								font-size: .9rem;
								color: $spanish-gray;
								&.day-name {
									text-decoration: underline;
									font-weight: 600;
								}
							}
						}
						.activity-wrapper {
							display: flex;
							justify-content: space-between;
						}
						textarea {
							width: 100%;
							height: 165px;
							border-radius: 13px;
							padding-top: 20px;
							padding-left: 20px;
							font-size: .8rem;
						}
					}
				}

				#register {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					flex-flow: column;
					align-items: center;
					padding: 50px 0;

					.signup-wrapper {
						display: flex;
						flex-flow: column;
						gap: 20px;

						.signup-btn {
							background: transparent;
							border: 0;
							cursor: pointer;
							position: relative;

							h6 {
								text-align: center;
								color: $white;
								background: $pastel-red;
								font-size: .9rem;
								padding: 13px 55px;
								border-radius: 15px;
								font-weight: 400;
								//convert $pastel-red hex to rgba
								//rgba(255, 0, 0, 0.1)
								box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
							}
						}
					}
				}

				#login{
					width: 100vw;
					height: 100vh;
					display: flex;
					justify-content: center;
					align-items: center;

					.two-column {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 100%;
						width: 100%;

						.left, .right {
							width: 48%;
							height: 100%;
						}
						.right {
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
						.left {
							display: flex;
							position: relative;
							flex-flow: column;
							gap: 15px;
							align-items: center;
							justify-content: center;

							.text-wrapper {
								width: 80%;
								text-align: center;
								display: flex;
								justify-content: center;
								flex-flow: column;
								align-items: center;
								gap: 15px;
							}

							input {
								width: 100%;
								height: 40px;
								border-radius: 3px;
								border: solid 1px $spanish-gray;
								padding-left: 10px;
							}

							.login-btn {
								background: transparent;
								border: 0;
								cursor: pointer;
								position: relative;

								h6 {
									text-align: center;
									color: $white;
									background: $pastel-red;
									font-size: .9rem;
									padding: 13px 55px;
									border-radius: 15px;
									font-weight: 400;
									//convert $pastel-red hex to rgba
									//rgba(255, 0, 0, 0.1)
									box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
								}
							}
						}
					}
				}

				#settings {
					padding: 75px;
					height: 100vh;
					overflow-y: scroll;
					width: 80vw;
					.settings-wrapper {
						display: flex;
						flex-flow: column;
						gap: 35px;
					}
					#settings-tabs {
						width: 100%;
						display: flex;
						justify-content: space-around;
						border-bottom: 1px solid $dark-charcoal;
						.tab {
							width: 100%;
							padding: 10px;
							font-family: $product-sans;
							text-align: center;
							cursor: pointer;
							&.active {
								background: $button-blue;
								color: white;
							}
						}
					}
				}
				.required-skills {
					display: flex;
					gap: 10px;
					width: 100%;
					height: auto;
					flex-flow: column;
					margin-bottom: 10px;
					.required-skill {
						display: flex;
						align-items: center;
						gap: 15px;
						position: relative;
						width: max-content;
						.shareable-skill {
							position: absolute;
							top: 0;
							right: 30px;
							font-size: 12px;
							color: $button-blue;
							opacity: .4;
							font-weight: bold;
						}
						.delete-required-skill {
							font-family: $product-sans;
							color: red;
							font-size: 1rem;
						}
					}
				}
				.settings-block {
					&.days {
						display: flex;
						gap: 20px;
						flex-flow: column;
					}
					&.add {
						display: flex;
						flex-flow: column;
						gap: 10px;
						input:not(input[type="checkbox"]) {
							height: 40px;
							border-radius: 4px;
							border: solid 1px #9B9B9B;
							padding-left: 20px;
							font-size: .8rem;
						}
						label {
							font-size: .8rem;
							color: $spanish-gray;
							display: block;
						}
						select {
							height: 40px;
							border-radius: 4px;
							border: solid 1px #9B9B9B;
							font-size: .8rem;
						}
						textarea {
							height: 100px;
							width: 100%;
							border-radius: 4px;
							border: solid 1px #9B9B9B;
							padding-left: 20px;
							font-size: .8rem;
						}
						button {
							height: 40px;
							border-radius: 4px;
							border: none;
							background: $button-blue;
							color: white;
							font-size: .8rem;
							font-family: $product-sans;
							cursor: pointer;
						}
						.add-required-skill-wrapper {
							display: flex;
							gap: 10px;
							label {
								display: initial;
							}
						}
					}
					.input-wrapper {
						display: flex;
						align-items: center;
						gap: 10px;
						p {
							margin-bottom: 0;
						}
						input {
							border-left: none;
							border-right: none;
							border-top: none;
							border-bottom: solid 1px #9B9B9B;
							box-shadow: none;
							text-align: center;
							padding: 0 0 0 0;
							max-width: 40px;
						}
					}
					.activity {
						margin-bottom: 30px;
						input {
							font-family: $product-sans;
							border-top: unset;
							border-left: unset;
							border-right: unset;
							border-bottom: 1px solid $spanish-gray;
							font-size: 1rem;
						}
					}

					.day-wrapper {
						display: flex;
						flex-flow: column;
						gap: 20px;
						h6 {
							font-size: .9rem;
							margin-bottom: 0;
						}
						.day-header {
							display: flex;
							align-items: center;
							gap: 10px;
						}
						.select-all {
							color: $button-blue;
							font-size: 12px;
							&.active {
								color: $pastel-red
							}
						}
						.periods-wrapper {
							display: flex;
							gap: 10px;
							align-items: center;
							justify-content: stretch;
							.add-another-period {
								cursor: pointer;
								color: $button-blue;
								font-weight: bold;
							}
						}
						.period-wrapper {
							padding: 10px 20px;
							font-size: .9rem;
							border-radius: 4px;
							border: 1px solid $spanish-gray;
							color: $spanish-gray;
							cursor: pointer;
							font-family: $product-sans;
							position: relative;
							&.active {
								background: $button-blue;
								border-color: $button-blue;
								color: $white;
							}
						}
					}
					.toggle {
						margin: 15px 0;
						display: block;
						height: inherit;
						cursor: pointer;
						width: fit-content;
						span {
							padding: 5px 20px;
							border: 1px solid $spanish-gray;
							&.active {
								background: $button-blue;
								border-color: $button-blue;
								color: white;
							}
							&.no {
								border-top-right-radius: 10px;
								border-bottom-right-radius: 10px;
							}
							&.yes {
								border-top-left-radius: 10px;
								border-bottom-left-radius: 10px;
							}
						}
					}
				}

				#print-schedule {
					width: 100%;
					padding: 30px;
					.camper {
						grid-template-columns: repeat(3, 1fr) !important;
					}
					.counselors-by-day-wrapper {
						h6 {
							font-size: 10px;
						}
						.counselor-row {
							padding: 0;
						}
						.day {
							font-size: 1.2rem;
						}
					}
					.period-wrapper {
						h6 {
							display: inline-block;
							width: 25px;
							text-align: center;
							font-size: 15px;
							&:first-of-type {
								width: 100px;
							}
						}
					}
					.detail-info-wrapper {
						display: block;
					}
					.activity-roster-wrapper {
						margin-bottom: 30px;
						position: relative;
						width: calc(50% - 15px);
						display: block;
					
						&.schedule {
							page-break-after: always;
							position: relative !important;
						}
						// &.odd {
						// 	position: absolute;
						// } 
						// &.even {
						// 	margin-left: auto;
						// }
						&.clearfix {
							clear: both;
						}
					
						.top-row {
							h6 {
								display: inline-block;
								margin-right: 10px; // replacing the gap property
								margin-bottom: 0;
								&.day {
									color: $dark-charcoal;
									text-transform: capitalize;
								}
								&.period {
									color: $spanish-gray;
								}
								&.activity {
									color: $button-blue;
								}
							}
						}
					
						.activity-roster {
							.camper-row {
								.camper-column, .empty-column {
									display: table-cell;
									text-align: center; // horizontally center the content
									line-height: normal; // vertically center the content, you might need to adjust this value
									padding: 3px;
									border: 1px solid black;
									box-sizing: border-box;
									width: 25px;
									h6 {
										margin: 0;
										font-size: 11px;
									}
									&:first-of-type {
										width: 100px;
									}
								}
							}
						}
					}
				}

				#schedule {
					margin-top: 20px;
					.campers-wrapper, .counselors-wrapper {
						display: flex;
						flex-flow: column;
						gap: 15px;
						width: 100%;
						margin-top: 30px;
						.camper, .counselor {
							display: grid;
							grid-auto-rows: minmax(min-content, max-content);
							grid-template-columns: repeat(3, 1fr);
							justify-items: center;
							width: fit-content;
							height: auto;
							width: 100%;
							select {
								width: calc(100% - 100px);
								padding: 0;
								border: unset;
								border-bottom: 1px solid $spanish-gray;
								&.missing {
									border: 2px solid $pastel-red;
									border-radius: 5px;
									color: $pastel-red;
								}
							}
							h2 {
								text-align: left;
								width: 100%;
								border-bottom: 2px solid;
								padding-bottom: 7px;
							}
							h6 {
								font-size: 16px;
								margin-bottom: 0;
								padding: 3.1px;
							}
							.activity-block {
								display: flex;
								width: 100%;
								position: relative;
							}
							.activity-wrapper {
								text-align: left;
								width: 100%;
								position: relative;
								display: flex;
								flex-flow: column;
								&:first-of-type {
									text-align: right;
									padding-right: 25px;
									width: max-content;
									position: absolute;
									left: -55px;
									top: 0;
									height: 100%;
									&:after {
										content: '';
										position: absolute;
										top: 0;
										right: 15px;
										width: 2px;
										height: 100%;
										background: $spanish-gray;
									}
								}
								&.days {
									display: flex;
									flex-flow: column;
									gap: 5px;
									h6 {
										&:nth-child(even) {
											background: #e7e7e7;
										}
									}
								}
							}
							.day {
								width: 100%;
								h6 {
									&:nth-child(even) {
										background: #e7e7e7;
									}
								}
							}
						}
						.counselor {
							gap: 10px;
							row-gap: 20px;
						}
					}
				}
				#scheduler {
					#date-wrapper {
						display: flex;
						justify-content: space-between;
						align-items: center;

						button {
							position: relative;
							background: transparent;
							border: 0;
							cursor: pointer;

							h6 {
								color: $white;
								background: $pastel-red;
								font-size: .9rem;
								padding: 5px 20px;
								border-radius: 10px;
								font-weight: 400;
								//convert $pastel-red hex to rgba
								//rgba(255, 0, 0, 0.1)
								box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
							}
						}
					}
					.tab-wrapper {
						width: 100%;
						display: flex;
						justify-content: space-between;
						margin-bottom: 30px;
						.tab {
							width: 50%;
							height: 45px;
							font-size: 18px;
							font-family: $product-sans;
							background: $spanish-gray;
							color: $dark-charcoal;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							&.active {
								background: $button-blue;
								color: white;
							}
						}
					}
					.schedule-wrapper {
						display: flex;
						justify-content: space-between;
						align-items: center;

						#schedule-search {
							width: 300px;
							height: 40px;
							margin-bottom: 15px;
						}

						button {
							position: relative;
							background: transparent;
							border: 0;
							cursor: pointer;

							h6 {
								color: $white;
								background: $pastel-red;
								font-size: .9rem;
								padding: 5px 20px;
								border-radius: 10px;
								font-weight: 400;
								//convert $pastel-red hex to rgba
								//rgba(255, 0, 0, 0.1)
								box-shadow: 0px 20px 10px -17px rgba(255, 0, 0, 0.4);
							}
						}
					}
					.filters-wrapper {
						display: flex;
						width: 100%;
						gap: 10px;
						justify-content: space-between;
						.filter-wrapper {
							width: 100%;
							input[type='checkbox'],label {
								cursor: pointer;
							}
							&.days {
								li {
									width: 30% !important;
								}
							}
						}
					}
					ul {
						list-style-type: none;
						padding-inline-start: 0;
						margin: 0;
						display: flex;
						justify-content: space-between;
						flex-flow: wrap;
						margin-bottom: 20px;
						li {
							width: 20%;
						}
					}
					.activities-schedule {
						display: flex;
						flex-flow: column;
						gap: 50px;
					}
					.schedule-activity-wrapper {
						.activity-name {
							color: $button-blue;
						}
						.camper-cabin {
							color: $spanish-gray;
							font-size: 14px;
							margin-bottom: 5px;
						}
						.camper-number {
							color: $pastel-red;
						}
						.activity {
							display: flex;
							flex-flow: column;
							gap: 50px;
							.period {
								display: flex;
								.column {
									margin-left: 50px;
								}
								.period-wrapper {
									p, .cabin {
										font-size: 15px;
										font-weight: 300;
										font-family: $product-sans;
									}
									p {
										margin-bottom: 5px;
									}
									.cabins {
										font-weight: bold;
									}
								}
								.campers {
									display: grid;
									grid-auto-rows: minmax(min-content, max-content);
									grid-template-columns: repeat(5, 1fr);
									grid-gap: 10px;
									justify-items: center;
									width: fit-content;
									height: auto;
									width: 100%;
									.individual-camper {
										width: 100%;
										.name {
											display: flex;
											gap: 3px;
											h6 {
												font-size: 16px;
												margin-bottom: 3px;
											}
										}
									}
								}
								.counselors {
									display: flex;
									flex-flow: column;
									.counselor-day {
										font-size: 20px;
										color: $button-blue;
									}
									h6 {
										font-size: 15px;
										margin-bottom: 3px;
									}
									.individual-counselor {
										display: flex;
										width: 100%;
										flex-flow: column;
										gap: 3px;
										.period-role, .name {
											display: flex;
											gap: 4px;
										}
										.period-role {
											h6 {
												color: $pastel-red;
											}
										}
									}
									.period-counselors-wrapper {
										display: flex;
										gap: 20px;
									}
								}
							}
						}
					}
					.warnings-wrapper {
						margin: 30px 0;
						&.campers {
							display: grid;
							grid-auto-rows: minmax(min-content, max-content);
							grid-template-columns: repeat(7, 1fr);
							grid-gap: 10px;
							justify-items: center;
						}
						&.counselors {
							display: grid;
							grid-auto-rows: minmax(min-content, max-content);
							grid-template-columns: repeat(3, 1fr);
							grid-gap: 10px;
							justify-items: center;
						}
						&.batch {
							display: grid;
							grid-template-columns: repeat(3, 1fr);
							grid-gap: 15px;
							h6 {
								font-size: 22px;
								color: $pastel-red !important;
								margin-bottom: 5px;
							}
							p {
								font-size: 16px;
								margin-bottom: 0 !important;
							}
						}
						.warning {
							width: 100%;
							&.camper {
								h6 {
									font-size: 16px;
									color: $dark-charcoal;
								}
								.periods {
									display: flex;
									gap: 5px;
									h6 {
										font-size: 14px;
										color: $pastel-red;
									}
								}
								p {
									margin: 0 !important;
								}
							}
							&.activity {
								h6 {
									font-size: 16px;
									color: $dark-charcoal;
								}
								.day-title {
									text-transform: capitalize;
								}
								.periods {
									display: flex;
									flex-flow: column;
									gap: 5px;
									.period {
										display: flex;
										gap: 5px;
										.period-title {
											color: $button-blue;
										}
										.roles {
											display: flex;
											gap: 5px;
										}
									}
									h6 {
										font-size: 14px;
										color: $pastel-red;
									}
								}
							}
						}
					}
				}
            }
        }
    }
}